import React, { useContext, useState, useEffect } from "react";
import { Tabs, Tab, Accordion } from "react-bootstrap";
import { Context } from "..";
import {
	ACQUISITION_ROUTE,
	DECISION_PROCESS_ROUTE,
	DRAFT_DECISIONS_ROUTE,
	INITIATION_ROUTE,
	PUBLIC_CONSULTATIONS_ROUTE,
	PUBLIC_INFORMATION_ROUTE,
	RECOMENDATIONS_ROUTE,
	TENDERS_ROUTE,
	VACANCIES_ROUTE,
} from "../utils/consts";
import { Link } from "react-router-dom";
import {
	fetchAcquisition,
	fetchConsultations,
	fetchInitiations,
	fetchRecommendations,
	fetchTenders,
	fetchVacancies,
} from "../http/informationProcessAPI";
import { Spinner } from "react-bootstrap";
import { fetchDraftDecisions } from "../http/navLinksAPI";

const InformationProcess = () => {
	const { informationProcess } = useContext(Context);

	const [loading, setLoading] = useState(true);
	useEffect(() => {
		fetchTenders()
			.then((data) => {
				informationProcess.setTenders(data);
			})
			.then(() =>
				fetchAcquisition()
					.then((data) => {
						informationProcess.setAcquisition(data);
					})
					.then(() =>
						fetchVacancies()
							.then((data) => {
								informationProcess.setVacancies(data);
							})
							.then(() =>
								fetchConsultations()
									.then((data) => {
										informationProcess.setConsultation(data);
									})
									.then(() =>
										fetchInitiations()
											.then((data) => {
												informationProcess.setInitiation(data);
											})
											.then(() =>
												fetchRecommendations()
													.then((data) => {
														informationProcess.setRecommendations(data);
													})
													.then(() =>
														fetchDraftDecisions()
															.then((data) => {
																informationProcess.setDraftDecisions(data);
															})
															.finally(() => setLoading(false)),
													),
											),
									),
							),
					),
			);
	}, [informationProcess]);

	if (loading) {
		return (
			<div className="d-flex align-items-center justify-content-center py-5">
				<Spinner animation={"grow"} />
			</div>
		);
	}
	return (
		<div id="transparency" className="d-flex flex-xl-row flex-column px-xl-5 px-2 py-5 border-bottom">
			<div className="d-flex flex-column px-xl-3 col-xl-6 col-12">
				<Link
					to={PUBLIC_INFORMATION_ROUTE}
					className="d-flex justify-content-start align-items-center mb-5 text-decoration-none text-primary font-3 ps-xl-4 pt-5 pt-xl-0 pt-xl-0"
				>
					Informații publice
					<i className="bi bi-arrow-right ps-3 icon-3" />
				</Link>
				<Tabs
					defaultActiveKey="tenders"
					id="uncontrolled-tab-example"
					className="d-flex align-items-center justify-content-center mb-3 text-primary fw-bold"
				>
					<Tab eventKey="tenders" title="Licitaţii şi achiziţii">
						<div className="d-flex flex-column">
							<Accordion className="pb-4">
								{informationProcess.tenders.count > 0
									? informationProcess.tenders.rows.map((item, idx) =>
											item.tenders_files.length > 0 ? (
												<Accordion.Item
													eventKey={"e_tender-" + idx}
													key={"tender-" + idx}
													className="border-0"
												>
													<Accordion.Header>
														<Link
															className="fw-bold ps-xl-1 font-2 text-primary text-justify pe-4 text-decoration-none truncate"
															to={TENDERS_ROUTE + "/" + item.id}
														>
															{item.title}
														</Link>
													</Accordion.Header>
													<div className="d-flex justify-content-start py-1 ps-4">
														<span className="border rounded border-primary px-2 text-primary">
															{item.date}
														</span>
													</div>
													<Accordion.Body>
														{item.tenders_files.map((sub_item, sub_idx) => (
															<Link
																to={process.env.REACT_APP_API_URL + sub_item.doc}
																key={"tenders_files" + sub_idx}
																className="text-primary text-decoration-none"
															>
																<div className="d-flex align-items-center">
																	<i
																		className={`bi bi-filetype-${sub_item.doc_type} icon-2 text-primary rounded ms-4 px-2 text-primary`}
																	/>
																	<span
																		className="text-wrap"
																		style={{ textAlign: "justify" }}
																	>
																		{sub_item.doc.split(".")[0]}
																	</span>
																</div>
															</Link>
														))}
													</Accordion.Body>
												</Accordion.Item>
											) : (
												<div className="d-flex flex-column mb-4 ps-4 ps-xl-0">
													<Link
														className="fw-bold font-2 ps-xl-4 text-primary text-justify pe-4 text-decoration-none truncate"
														to={TENDERS_ROUTE + "/" + item.id}
													>
														{item.title}
													</Link>
													<div className="d-flex justify-content-start py-2 ps-xl-4 pt-3">
														<span className="border rounded border-primary px-2 text-primary">
															{item.date}
														</span>
													</div>
												</div>
											),
									  )
									: null}
							</Accordion>
						</div>
					</Tab>
					<Tab eventKey="acquisition" title="Planuri de achiziții">
						<div className="d-flex flex-column">
							<Accordion className="pb-4">
								{informationProcess.acquisition.count > 0
									? informationProcess.acquisition.rows.map((item, idx) =>
											item.acquisition_files.length > 0 ? (
												<Accordion.Item
													eventKey={"e_acquisition-" + idx}
													key={"acquisition-" + idx}
													className="border-0"
												>
													<Accordion.Header>
														<Link
															className="fw-bold ps-xl-1 font-2 text-primary text-justify pe-4 text-decoration-none truncate"
															to={ACQUISITION_ROUTE + "/" + item.id}
														>
															{item.title}
														</Link>
													</Accordion.Header>
													<div className="d-flex justify-content-start py-2 ps-4">
														<span className="border rounded border-primary px-2 text-primary">
															{item.date}
														</span>
													</div>
													<Accordion.Body>
														{item.acquisition_files.map((sub_item, sub_idx) => (
															<Link
																to={process.env.REACT_APP_API_URL + sub_item.doc}
																key={"acquisition_files" + sub_idx}
																className="text-primary text-decoration-none"
															>
																<div className="d-flex align-items-center">
																	<i
																		className={`bi bi-filetype-${sub_item.doc_type} icon-2 text-primary rounded ms-4 px-2 text-primary`}
																	/>
																	<span
																		className="text-wrap"
																		style={{ textAlign: "justify" }}
																	>
																		{sub_item.doc.split(".")[0]}
																	</span>
																</div>
															</Link>
														))}
													</Accordion.Body>
												</Accordion.Item>
											) : (
												<div className="d-flex flex-column mb-4 ps-4 ps-xl-0">
													<Link
														className="fw-bold font-2 ps-xl-4 text-primary text-justify pe-4 text-decoration-none truncate"
														to={ACQUISITION_ROUTE + "/" + item.id}
													>
														{item.title}
													</Link>
													<div className="d-flex justify-content-start py-2 ps-xl-4  pt-3">
														<span className="border rounded border-primary px-2 text-primary">
															{item.date}
														</span>
													</div>
												</div>
											),
									  )
									: null}
							</Accordion>
						</div>
					</Tab>
					<Tab eventKey="vacancies" title="Posturi vacante">
						<div className="d-flex flex-column">
							<Accordion className="pb-4">
								{informationProcess.vacancies.count > 0
									? informationProcess.vacancies.rows.map((item, idx) =>
											item.vacancies_files.length > 0 ? (
												<Accordion.Item
													eventKey={"e_vacancy-" + idx}
													key={"vacancy-" + idx}
													className="border-0"
												>
													<Accordion.Header>
														<Link
															className="fw-bold font-2 text-primary text-justify pe-4 text-decoration-none truncate"
															to={VACANCIES_ROUTE + "/" + item.id}
														>
															{item.title}
														</Link>
													</Accordion.Header>
													<div className="d-flex justify-content-start py-2 ps-4">
														<span className="border rounded border-primary px-2 text-primary">
															{item.date}
														</span>
													</div>
													<Accordion.Body>
														{item.vacancies_files.map((sub_item, sub_idx) => (
															<Link
																to={process.env.REACT_APP_API_URL + sub_item.doc}
																key={"vacancies_files" + sub_idx}
																className="text-primary text-decoration-none"
															>
																<div className="d-flex align-items-center">
																	<i
																		className={`bi bi-filetype-${sub_item.doc_type} icon-2 text-primary rounded ms-4 px-2 text-primary`}
																	/>
																	<span
																		className="text-wrap"
																		style={{ textAlign: "justify" }}
																	>
																		{sub_item.doc.split(".")[0]}
																	</span>
																</div>
															</Link>
														))}
													</Accordion.Body>
												</Accordion.Item>
											) : (
												<div className="d-flex flex-column mb-4 ps-4 ps-xl-0">
													<Link
														className="fw-bold font-2 ps-xl-4 text-primary text-justify pe-4 text-decoration-none truncate"
														to={VACANCIES_ROUTE + "/" + item.id}
													>
														{item.title}
													</Link>
													<div className="d-flex justify-content-start ps-xl-4 py-2 pt-3">
														<span className="border rounded border-primary px-2 text-primary">
															{item.date}
														</span>
													</div>
												</div>
											),
									  )
									: null}
							</Accordion>
						</div>
					</Tab>
				</Tabs>
			</div>
			<div className="d-flex flex-column px-xl-3 col-xl-6 col-12">
				<Link
					to={DECISION_PROCESS_ROUTE}
					className="d-flex justify-content-start align-items-center mb-5 text-decoration-none text-primary font-3 ps-xl-4 pt-5 pt-md-0 pt-xl-0"
				>
					Procesul decizional <i className="bi bi-arrow-right ps-3 icon-b" />
				</Link>
				<Tabs
					defaultActiveKey="consultation"
					id="uncontrolled-tab-example"
					className="d-flex align-items-center justify-content-center mb-3 text-primary fw-bold"
				>
					<Tab eventKey="consultation" title="Consultare publică">
						{informationProcess.consultation.count > 0
							? informationProcess.consultation.rows.map((item, idx) => (
									<Link
										key={"consultation" + idx}
										className="d-flex flex-column justify-content-start py-3 text-decoration-none px-4 ps-xl-0"
										to={PUBLIC_CONSULTATIONS_ROUTE + "/" + item.id}
									>
										<span className="fw-bold font-2 text-primary text-justify truncate">
											{item.title}
										</span>
										<div className="d-flex justify-content-start py-2">
											<span className="border rounded border-primary px-2 text-primary">
												{item.date}
											</span>
										</div>
									</Link>
							  ))
							: null}
					</Tab>
					<Tab eventKey="initiation" title="Inițiere proiect">
						{informationProcess.initiation.count > 0
							? informationProcess.initiation.rows.map((item, idx) => (
									<Link
										key={"initiation" + idx}
										className="d-flex flex-column justify-content-start py-3 text-decoration-none px-4 ps-xl-0"
										to={INITIATION_ROUTE + "/" + item.id}
									>
										<span className="fw-bold font-2 text-primary text-justify truncate">
											{item.title}
										</span>
										<div className="d-flex justify-content-start py-2">
											<span className="border rounded border-primary px-2 text-primary">
												{item.date}
											</span>
										</div>
									</Link>
							  ))
							: null}
					</Tab>
					<Tab eventKey="draftDecisions" title="Proiecte de Decizii">
						{informationProcess.draftDecisions.count > 0
							? informationProcess.draftDecisions.rows.map((item, idx) => (
									<Link
										key={"draft_decision" + idx}
										className="d-flex flex-column justify-content-start py-3 text-decoration-none px-4 ps-xl-0"
										to={DRAFT_DECISIONS_ROUTE + "/" + item.id}
									>
										<span className="fw-bold font-2 text-primary text-justify truncate">
											{item.title}
										</span>
										<div className="d-flex justify-content-start py-2">
											<span className="border rounded border-primary px-2 text-primary">
												{item.date}
											</span>
										</div>
									</Link>
							  ))
							: null}
					</Tab>
					<Tab eventKey="recommendations" title="Sinteza recomandărilor">
						{informationProcess.recommendations.count > 0
							? informationProcess.recommendations.rows.map((item, idx) => (
									<Link
										key={"recommendations" + idx}
										className="d-flex flex-column justify-content-start py-3 text-decoration-none px-4 ps-xl-0"
										to={RECOMENDATIONS_ROUTE + "/" + item.id}
									>
										<span className="fw-bold font-2 text-primary text-justify truncate">
											{item.title}
										</span>
										<div className="d-flex justify-content-start py-2">
											<span className="border rounded border-primary px-2 text-primary">
												{item.date}
											</span>
										</div>
									</Link>
							  ))
							: null}
					</Tab>
				</Tabs>
			</div>
		</div>
	);
};

export default InformationProcess;
