import { useContext, useState, useEffect } from "react";
import { Spinner, Card, Accordion, Form, useAccordionButton, Button } from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import { observer } from "mobx-react-lite";
import { Context } from "../../index";
import { fetchLastNews, removeOneLastNews, updateOneLastNews } from "../../http/NewsAPI";
import parse from "html-react-parser";
import AddContactsModal from "./Modals/AddContactsModal";
import { fetchContacts, removeOneContacts, removeOneEmail, removeOnePhone, updateOneContacts } from "../../http/navLinksAPI";

const ContactsAdmin = observer(() => {
	// const { contacts } = useContext(Context);
	const [contacts, setContacts] = useState([null]);
	const [show, setShow] = useState(false);

	const [title, setTitle] = useState("");
	const [location, setLocation] = useState("");
	const [locationLink, setLocationLink] = useState("");
	const [responsible, setResponsible] = useState("");
	const [days, setDays] = useState("");
	const [time, setTime] = useState("");
	const [email, setEmail] = useState([]);
	const [phone, setPhone] = useState([]);
	const [emailChanges, setEmailChanges] = useState([]);
	const [phoneChanges, setPhoneChanges] = useState([]);

	const addPhone = () => {
		setPhone([...phone, { phone: "", number: Date.now() }]);
	};

	const removePhone = (number) => {
		setPhone(phone.filter((i) => i.number !== number));
	};

	const changePhone = (key, value, number) => {
		setPhone(phone.map((i) => (i.number === number ? { ...i, [key]: value } : i)));
	};

	const changePhoneChanges = (sub_item, key, value) => {
		const escapeDublication = phoneChanges.some((item) => item.number === sub_item.id);
		const newPhone = {
			phone: sub_item.phone,
			number: sub_item.id,
		};
		if (!escapeDublication) {
			setPhoneChanges((prevPhone) => [...prevPhone, newPhone]);
		} else {
			setPhoneChanges((prevPhone) => prevPhone.map((i) => (i.number === sub_item.id ? { ...i, [key]: value } : i)));
		}
	};

	const removePhoneChanges = (sub_item) => {
		const elem_id = sub_item.id;
		removeOnePhone(elem_id).then(() =>
			contacts.setContacts((old_data) => {
				const updated_data = contacts.rows.map((committee) => ({
					...committee,
					contacts_phones: contacts.contacts_phones.filter((phone) => phone.id !== elem_id),
				}));

				return { ...old_data, rows: updated_data };
			})
		);
	};

	const addEmail = () => {
		setEmail([...email, { email: "", number: Date.now() }]);
	};

	const removeEmail = (number) => {
		setEmail(email.filter((i) => i.number !== number));
	};

	const changeEmail = (key, value, number) => {
		setEmail(email.map((i) => (i.number === number ? { ...i, [key]: value } : i)));
	};

	const changeEmailChanges = (sub_item, key, value) => {
		const escapeDublication = emailChanges.some((item) => item.number === sub_item.id);
		const newEmail = {
			email: sub_item.email,
			number: sub_item.id,
		};
		if (!escapeDublication) {
			setEmailChanges((prevEmail) => [...prevEmail, newEmail]);
		} else {
			setEmailChanges((prevEmail) => prevEmail.map((i) => (i.number === sub_item.id ? { ...i, [key]: value } : i)));
		}
	};

	const removeEmailChanges = (sub_item) => {
		const elem_id = sub_item.id;
		removeOneEmail(elem_id).then(() =>
			setContacts((old_data) => {
				const updated_data = contacts.rows.map((items) => ({
					...items,
					contacts_emails: items.contacts_emails.filter((email) => email.id !== elem_id),
				}));

				return { ...old_data, rows: updated_data };
			})
		);
	};

	const [loading, setLoading] = useState(true);
	useEffect(() => {
		fetchContacts()
			.then((data) => {
				setContacts(data);
			})
			.finally(() => setLoading(false));
	}, contacts);

	const CustomToggle = ({ children, eventKey }) => {
		const decoratedOnClick = useAccordionButton(eventKey);

		return (
			<button
				className='btn btn-primary d-flex align-items-center mb-xl-1 mx-xl-0 mx-2'
				onClick={decoratedOnClick}>
				{children}
			</button>
		);
	};

	const removeContact = (item) => {
		removeOneContacts(item.id).then(() => window.location.reload());
	};

	const editContacts = (data) => {
		const id = data.id;
		const formData = new FormData();
		if (title != "") formData.append("title", title);
		if (location != "") formData.append("location", location);
		if (locationLink != "") formData.append("location_link", locationLink);
		if (responsible != "") formData.append("responsible", responsible);
		if (days != "") formData.append("days", days);
		if (time != "") formData.append("time", time);
		if (email.length > 0) formData.append("emails", JSON.stringify(email));
		if (phone.length > 0) formData.append("phones", JSON.stringify(phone));
		if (emailChanges.length > 0) formData.append("emailsChanges", JSON.stringify(emailChanges));
		if (phoneChanges.length > 0) formData.append("phonesChanges", JSON.stringify(phoneChanges));
		console.log(...formData);
		console.log(JSON.stringify(...formData));

		updateOneContacts(formData, id).then(() => window.location.reload());
	};

	if (loading) {
		return (
			<div className='d-flex align-items-center justify-content-center py-5'>
				<Spinner animation={"grow"} />
			</div>
		);
	}
	return (
		<div className='d-flex flex-column bg-white r-corner-radius w-100'>
			<div className='d-flex justify-content-between align-items-center font-2 fw-bold text-primary shadow-sm ps-3'>
				<span>Contacte</span>
				<button
					className='btn btn-primary r-u-corner-radius'
					style={{ height: 70, width: 70 }}
					onClick={() => setShow(true)}>
					<i className='bi bi-plus-circle icon-2' />
				</button>
			</div>
			<div
				className='px-5 mt-4'
				style={{ maxHeight: 1080, overflow: "auto" }}>
				<Accordion className='pb-4'>
					{contacts.count > 0
						? contacts.rows.map((item, idx) => (
								<div
									key={idx}
									className='shadow-sm border-0 my-2 px-4'>
									<Card.Body className='d-flex flex-column'>
										<div className='d-flex flex-xl-row flex-column align-items-center justify-content-between'>
											<div>
												<div className='text-decoration-none text-dark'>
													<Card.Title className='text-primary truncate'>{item.title}</Card.Title>
												</div>
												<div className='d-flex flex-row flex-wrap align-items-center justify-content-around py-2'>
													<span className='border border-primary rounded text-primary p-1 mx-2 my-xl-0 my-1'>
														{item.location}
													</span>
													<span className='border border-primary rounded text-primary p-1 mx-2 my-xl-0 my-1'>
														{item.responsible}
													</span>
													<span className='border border-primary rounded text-primary p-1 mx-2 my-xl-0 my-1'>
														{item.days}
													</span>
													<span className='border border-primary rounded text-primary p-1 mx-2 my-xl-0 my-1'>
														{item.time}
													</span>
												</div>
											</div>
											<div className='d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3'>
												<CustomToggle
													eventKey={idx}
													onClick={() => {
														useAccordionButton({ idx });
													}}>
													<i className='bi bi-pencil-square icon-2 pe-2' />
													<span>Edit</span>
												</CustomToggle>
												<button
													className='btn btn-primary d-flex align-items-center mx-xl-0 mx-2'
													onClick={() => removeContact(item)}>
													<i className='bi bi-x-circle icon-2 pe-2' />
													<span>Remove</span>
												</button>
											</div>
										</div>
									</Card.Body>
									<Accordion.Collapse eventKey={idx}>
										<div className='d-flex flex-column justify-content-around px-4 py-4 border-top'>
											<span className='text-primary  font-2 py-2 ps-1'>Denumirea Diviziunii</span>
											<Form.Control
												aria-label='Title'
												placeholder={item.title}
												value={title}
												onChange={(e) => {
													setTitle(e.target.value);
												}}
											/>
											<span className='text-primary  font-2 py-2 ps-1'>Locatia</span>
											<Form.Control
												aria-label='Title'
												placeholder={item.location}
												value={location}
												onChange={(e) => {
													setLocation(e.target.value);
												}}
											/>
											<span className='text-primary  font-2 py-2 ps-1'>Legătură cu harta</span>
											<Form.Control
												aria-label='Title'
												placeholder={item.location_link}
												value={locationLink}
												onChange={(e) => {
													setLocationLink(e.target.value);
												}}
											/>
											<span className='text-primary  font-2 py-2 ps-1'>Persoana Responsabila</span>
											<Form.Control
												aria-label='Title'
												placeholder={item.responsible}
												value={responsible}
												onChange={(e) => {
													setResponsible(e.target.value);
												}}
											/>
											<span className='text-primary  font-2 py-2 ps-1'>Program de lucru</span>
											<Form.Control
												aria-label='Title'
												placeholder={item.days}
												value={days}
												onChange={(e) => {
													setDays(e.target.value);
												}}
											/>
											<span className='text-primary  font-2 py-2 ps-1'>Orele de Lucru</span>
											<Form.Control
												aria-label='Title'
												placeholder={item.time}
												value={time}
												onChange={(e) => {
													setTime(e.target.value);
												}}
											/>
											<span className='text-primary  font-2 py-2 ps-1'>E-Mail</span>
											<Button
												onClick={addEmail}
												className='btn-primary mb-4'>
												Adauga
											</Button>
											{email.map((i) => (
												<div
													key={i.number}
													className='d-flex flex-xl-row flex-column align-items-center justify-content-around'>
													<Form.Control
														aria-label='Title'
														placeholder='E-Mail'
														value={i.email}
														className='me-xl-5'
														onChange={(e) => changeEmail("email", e.target.value, i.number)}
													/>
													<Button
														variant={"outline-danger"}
														className='my-sm-4'
														onClick={() => removeEmail(i.number)}>
														Sterge
													</Button>
												</div>
											))}
											{item.contacts_emails.length > 0
												? item.contacts_emails.map((sub_item, sub_idx) => (
														<div
															key={sub_idx}
															className='d-flex flex-xl-row flex-column align-items-center justify-content-around'>
															<Form.Control
																aria-label='Title'
																className='me-xl-5'
																placeholder={sub_item.email}
																onChange={(e) => changeEmailChanges(sub_item, "email", e.target.value)}
															/>
															<Button
																variant={"outline-danger"}
																className='my-sm-4'
																onClick={() => removeEmailChanges(sub_item)}>
																Sterge
															</Button>
														</div>
												  ))
												: null}

											<span className='text-primary  font-2 py-2 ps-1'>Nr. de Telefon</span>
											<Button
												onClick={addPhone}
												className='btn-primary mb-4'>
												Adauga
											</Button>
											{phone.map((i) => (
												<div
													key={i.number}
													className='d-flex flex-xl-row flex-column align-items-center justify-content-around'>
													<Form.Control
														aria-label='Title'
														placeholder='Nr. de Telefon'
														value={i.phone}
														className='me-xl-5'
														onChange={(e) => changePhone("phone", e.target.value, i.number)}
													/>
													<Button
														variant={"outline-danger"}
														className='my-sm-4'
														onClick={() => removePhone(i.number)}>
														Sterge
													</Button>
												</div>
											))}
											{item.contacts_phones.length > 0
												? item.contacts_phones.map((sub_item, sub_idx) => (
														<div
															key={sub_idx}
															className='d-flex flex-xl-row flex-column align-items-center justify-content-around'>
															<Form.Control
																aria-label='Title'
																className='me-xl-5'
																placeholder={sub_item.phone}
																onChange={(e) => changePhoneChanges(sub_item, "phone", e.target.value)}
															/>
															<Button
																variant={"outline-danger"}
																className='my-sm-4'
																onClick={() => removePhoneChanges(sub_item)}>
																Sterge
															</Button>
														</div>
												  ))
												: null}

											<button
												className='btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4'
												onClick={() => editContacts(item)}>
												<i className='bi bi-check icon-2 pe-2' />
												<span>Confirm</span>
											</button>
										</div>
									</Accordion.Collapse>
								</div>
						  ))
						: null}
				</Accordion>

				{/* <div className='d-flex flex-column justify-content-around'>
					<span className='text-primary  font-2 py-2 ps-1'>Denumire</span>
					<Form.Control
						aria-label='Title'
						value={title}
						onChange={(e) => {
							setTitle(e.target.value);
						}}
					/>
					<span className='text-primary  font-2 py-2 ps-1'>Descrieree</span>
					<ReactQuill
						theme='snow'
						value={description}
						modules={modules}
						formats={formats}
						onChange={setDescription}
					/>
				</div> */}
			</div>
			<AddContactsModal
				newsType={"lastNews"}
				show={show}
				onHide={() => setShow(false)}
			/>
		</div>
	);
});

export default ContactsAdmin;
